var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isVerified)?_c('v-form',{staticClass:"tw-mt-28",attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.handleSendOtp.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-otp-input',{staticClass:"otpForm tw-space-s-14 tw-flex-nowrap",attrs:{"dark":"","plain":"","length":"4","color":"#A53E50","placeholder":"0"},on:{"finish":_vm.handleSendOtp},model:{value:(_vm.otpNumber),callback:function ($$v) {_vm.otpNumber=$$v},expression:"otpNumber"}}),_c('div',{staticClass:"text-center tw-mt-20 lg:tw-mt-64"},[_c('span',{staticClass:"tw-underline tw-cursor-pointer hover:tw-opacity-70 tw-text-white",on:{"click":function($event){return _vm.$emit('resendVerificaiton')}}},[_vm._v(" "+_vm._s(_vm.$t('resetPassowrd.resendVerify'))+" ")]),_c('v-btn',{staticClass:"shadow tw-mt-4 lg:tw-mt-16",attrs:{"disabled":_vm.otpNumber.length < 4,"dark":"","height":"60","block":"","type":"submit","color":"#A53E50","loading":_vm.loading}},[_c('span',{staticClass:"tw-text-18"},[_vm._v(_vm._s(_vm.$t('resetPassowrd.verifyOTP')))])])],1)],1):_c('v-form',{ref:"changePassword",staticClass:"tw-mt-28",attrs:{"disabled":_vm.loading},on:{"submit":function($event){$event.preventDefault();return _vm.resendVerificaiton.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('div',{staticClass:"tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-white",attrs:{"for":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-email")]),_c('div',{staticClass:"tw-mx-4"},[_vm._v(" "+_vm._s(_vm.$t('resetPassowrd.password'))+" ")])],1),_c('v-text-field',{staticClass:"focus-bg-none",attrs:{"solo":"","hide-details":"auto","rules":[
				_vm.rules.required(_vm.$t('resetPassowrd.password')),
				_vm.rules.password ],"type":_vm.password.show ? 'text' : 'password',"append-icon":_vm.password.show ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.password.show = !_vm.password.show}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('div',{staticClass:"tw-mb-4 tw-mt-8 tw-font-medium tw-flex tw-items-center tw-text-white",attrs:{"for":""}},[_c('v-icon',{staticClass:"tw-me-4",attrs:{"dark":""}},[_vm._v("mdi-lock")]),_c('div',{staticClass:"tw-mx-4"},[_vm._v(" "+_vm._s(_vm.$t('resetPassowrd.confirmPassword'))+" ")])],1),_c('v-text-field',{staticClass:"focus-bg-none",attrs:{"solo":"","hide-details":"auto","rules":[
				_vm.rules.required(_vm.$t('resetPassowrd.confirmPassword')),
				function (v) { return _vm.rules.match(
						v,
						_vm.user.password,
						_vm.$t('resetPassowrd.confirmPassword')
					); } ],"type":_vm.password.show ? 'text' : 'password',"append-icon":_vm.password.show ? 'mdi-eye' : 'mdi-eye-off'},on:{"click:append":function($event){_vm.password.show = !_vm.password.show}},model:{value:(_vm.user.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.user, "passwordConfirmation", $$v)},expression:"user.passwordConfirmation"}}),_c('div',{staticClass:"text-center tw-mt-60"},[_c('v-btn',{staticClass:"shadow tw-mt-4 lg:tw-mt-6",attrs:{"dark":"","height":"60","block":"","type":"submit","color":"#A53E50","loading":_vm.loading}},[_c('span',{staticClass:"tw-text-18"},[_vm._v(" "+_vm._s(_vm.$t('resetPassowrd.confirm'))+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }