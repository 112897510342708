<template>
	<v-app class="login no-auth">
		<div
			class="tw-h-screen tw-overflow-x-hidden tw-overflow-y-auto tw-flex tw-flex-col"
		>
			<v-row class="tw-flex-1">
				<v-col
					class="tw-hidden lg:tw-grid tw-place-content-center tw-px-4 md:tw-px-0"
				>
					<div class="tw-text-center tw-max-w-md">
						<img
							class="tw-h-52 tw-object-contain tw-mx-auto"
							src="@/assets/images/logo.png"
							alt
							srcset
						/>
						<h3
							class="tw-font-semibold tw-text-2xl md:tw-text-3xl 3xl:tw-text-4xl tw-mt-6 md:tw-mt-10 3xl:tw-mt-14"
						>
							{{ $t('login.welcomeToMachla') }}
						</h3>
						<p class=" 3xl:tw-text-xl tw-mt-3 3xl:tw-mt-5 tw-text-70">
							{{ $t('login.buyingAndSellingMakeEasy') }}
						</p>
						<div class="tw-mt-64">
							<BtnSignUp />
						</div>
					</div>
				</v-col>
				<v-col class="tw-grid tw-place-content-center tw-bg-72 tw-relative">
					<!-- Back Button -->
					<v-btn
						icon
						dark
						width="48"
						height="48"
						class="tw-bg-white tw-absolute tw-top-8 tw-left-8 xl:tw-top-24 xl:tw-left-24"
						@click="onClickBack"
					>
						<v-icon color="primary">mdi-chevron-left</v-icon>
					</v-btn>

					<div class="tw-w-full sm:tw-w-96 md:tw-w-420 tw-px-5 md:tw-px-0">
						<div v-if="!passwordChanged">
							<div>
								<h2 class="tw-font-semibold tw-text-27px tw-text-FF">
									{{ $t('resetPassowrd.verification') }}
								</h2>
								<p
									class="tw-text-17px tw-text-FF tw-mt-4 tw-opacity-70 tw-max-w-sm"
								>
									{{ $t('resetPassowrd.resetPasswordHint') }}
								</p>
							</div>
							<v-alert
								dense
								outlined
								dismissible
								v-model="error.dialog"
								:type="error.type"
								>{{ error.message }}</v-alert
							>

							<ResetPasswordForm
								:loading="loading"
								:isVerified="isVerified"
								:passwordChanged="passwordChanged"
								@handleSendOtp="sendOtp"
								@resendVerificaiton="handleResetVerify"
								@resetPass="handleResetPass"
							/>
						</div>
						<div class="tw-text-center" v-else>
							<img
								class="tw-w-60 md:tw-w-420 tw-object-cover tw-mx-auto"
								src="@/assets/images/successPass.png"
								alt
								srcset
							/>

							<h2
								class="tw-text-27px tw-font-semibold tw-text-white tw-mb-4 tw-mt-8"
							>
								{{ $t('resetPassowrd.passSuccess') }}
							</h2>
							<span class="tw-text-white tw-opacity-70">
								{{ $t('resetPassowrd.passSuccessMsg') }}
							</span>
							<div class="tw-mt-16 xl:tw-mt-24">
								<btn-sign-up>
									<template #activator="{on,attrs}">
										<v-btn
											v-on="on"
											v-bind="attrs"
											:height="$vuetify.breakpoint.mdAndUp ? 60 : ''"
											dark
											block
											depressed
											color="#A53E50"
										>
											<span class=""> {{ $t('login.signUp') }}</span>
										</v-btn>
									</template>
								</btn-sign-up>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>
	</v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { encodedCookies, hasHistory, omitEmpties } from 'vuelpers'

import BtnSignUp from '../components/BtnSignUp.vue'
import ResetPasswordForm from '@/components/forms/ResetPasswordForm'

export default {
	components: {
		ResetPasswordForm,
		BtnSignUp,
	},
	data() {
		return {
			loading: false,
			isVerified: false,
			passwordChanged: false,
			error: {
				dialog: false,
				message: '',
			},
		}
	},
	methods: {
		...mapActions('auth', [
			'verifyPhone',
			'verifyEmail',
			'resetbyPhone',
			'resetbyEmail',
		]),
		...mapActions('auth', ['forgotPassword']),
		onClickBack() {
			return hasHistory() ? this.$router.back() : this.$router.push('/')
		},
		async sendOtp(number) {
			// console.log(number, 'reset')
			// this.loading = true;
			// console.log(number)
			// let action =
			// 	encodedCookies.get('verifyType').verifyType == 'phone'
			// 		? 'verifyPhone'
			// 		: 'verifyEmail'
			const data = {
				token: number,
				type: encodedCookies.get('verifyType').verifyType,
				email:
					encodedCookies.get('verifyType').verifyType == 'email'
						? encodedCookies.get('verify').verify
						: '',
				phone:
					encodedCookies.get('verifyType').verifyType == 'phone'
						? encodedCookies.get('verify').verify
						: '',
			}
			const [err, res] =
				encodedCookies.get('verifyType').verifyType == 'phone'
					? await this.verifyPhone(omitEmpties(data))
					: await this.verifyEmail(omitEmpties(data))

			if (err) {
				this.loading = false
				this.error = {
					dialog: true,
					message: res.message,
				}
				return
			}

			this.isVerified = true
		},
		async handleResetVerify() {
			console.log('handleResetVerify')
			this.loading = true
			console.log(encodedCookies.get('verifyType', 'verify'))
			let type = encodedCookies.get('verifyType').verifyType
			let phone =
				encodedCookies.get('verifyType').verifyType == 'phone'
					? encodedCookies.get('verify').verify
					: ''
			let email =
				encodedCookies.get('verifyType').verifyType == 'email'
					? encodedCookies.get('verify').verify
					: ''
			// console.log(type, phone, email, 'dasdsadasdasdasdasd')
			let [err, res] = await this.forgotPassword(
				omitEmpties({ email: email, type: type, phone: phone })
			)
			if (err) {
				this.loading = false
				return (this.error = {
					dialog: true,
					message: res.message,
				})
			} else this.$toast.success(this.$t('verify-code-resent'))
			this.loading = false
		},
		async handleResetPass(data) {
			console.log(data)
			this.loading = true
			let type = encodedCookies.get('verifyType').verifyType
			let phone =
				encodedCookies.get('verifyType').verifyType == 'phone'
					? encodedCookies.get('verify').verify
					: ''
			let email =
				encodedCookies.get('verifyType').verifyType == 'email'
					? encodedCookies.get('verify').verify
					: ''
			const [err] =
				type == 'phone'
					? await this.resetbyPhone({ ...data, phone: phone })
					: await this.resetbyEmail({ ...data, email: email })
			if (err) {
				this.loading = false
				return (this.error = {
					dialog: true,
					message: this.$t('unexpected-error-occured'),
				})
			} else {
				this.passwordChanged = true
				this.loading = false
				console.log(this.passwordChanged)
			}
		},
	},
	created() {
		console.log(encodedCookies.get('verifyType', 'verify'))
	},
}
</script>

<style lang="scss" type="text/scss" scoped>
.login {
	&__card {
		width: 450px;
		max-width: 90%;
		@include center;
	}
}
</style>
