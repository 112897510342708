<template>
	<div>
		<v-form
			class="tw-mt-28"
			v-model="isValid"
			:disabled="loading"
			v-if="!isVerified"
			@submit.prevent="handleSendOtp"
		>
			<v-otp-input
				dark
				plain
				length="4"
				color="#A53E50"
				placeholder="0"
				class="otpForm tw-space-s-14 tw-flex-nowrap"
				v-model="otpNumber"
				@finish="handleSendOtp"
			></v-otp-input>

			<div class="text-center tw-mt-20 lg:tw-mt-64">
				<span
					@click="$emit('resendVerificaiton')"
					class="tw-underline tw-cursor-pointer hover:tw-opacity-70 tw-text-white"
				>
					{{ $t('resetPassowrd.resendVerify') }}
				</span>

				<v-btn
					:disabled="otpNumber.length < 4"
					dark
					height="60"
					block
					type="submit"
					class="shadow tw-mt-4 lg:tw-mt-16"
					color="#A53E50"
					:loading="loading"
				>
					<span class="tw-text-18">{{
						$t('resetPassowrd.verifyOTP')
					}}</span>
				</v-btn>
			</div>
		</v-form>
		<v-form
			class="tw-mt-28"
			v-model="isValid"
			:disabled="loading"
			v-else
			ref="changePassword"
			@submit.prevent="resendVerificaiton"
		>
			<div
				for
				class="tw-mb-4 tw-font-medium tw-flex tw-items-center tw-text-white"
			>
				<v-icon dark>mdi-email</v-icon>
				<div class="tw-mx-4">
					{{ $t('resetPassowrd.password') }}
				</div>
			</div>
			<v-text-field
				solo
				v-model="user.password"
				hide-details="auto"
				:rules="[
					rules.required($t('resetPassowrd.password')),
					rules.password,
				]"
				class="focus-bg-none"
				:type="password.show ? 'text' : 'password'"
				:append-icon="password.show ? 'mdi-eye' : 'mdi-eye-off'"
				@click:append="password.show = !password.show"
			/>
			<div
				for
				class="tw-mb-4 tw-mt-8 tw-font-medium tw-flex tw-items-center tw-text-white"
			>
				<v-icon dark class="tw-me-4">mdi-lock</v-icon>
				<div class="tw-mx-4">
					{{ $t('resetPassowrd.confirmPassword') }}
				</div>
			</div>

			<v-text-field
				solo
				v-model="user.passwordConfirmation"
				hide-details="auto"
				:rules="[
					rules.required($t('resetPassowrd.confirmPassword')),
					(v) =>
						rules.match(
							v,
							user.password,
							$t('resetPassowrd.confirmPassword')
						),
				]"
				class="focus-bg-none"
				:type="password.show ? 'text' : 'password'"
				:append-icon="password.show ? 'mdi-eye' : 'mdi-eye-off'"
				@click:append="password.show = !password.show"
			/>

			<div class="text-center tw-mt-60">
				<v-btn
					dark
					height="60"
					block
					type="submit"
					class="shadow tw-mt-4 lg:tw-mt-6"
					color="#A53E50"
					:loading="loading"
				>
					<span class="tw-text-18">
						{{ $t('resetPassowrd.confirm') }}
					</span>
				</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
import { createFormMixin } from '@/mixins/form-mixin'
export default {
	name: 'LoginForm',
	mixins: [
		createFormMixin({
			rules: ['required', 'email', 'password', 'match'],
		}),
	],
	props: {
		loading: Boolean,
		isVerified: Boolean,
		passwordChanged: Boolean,
	},
	data() {
		return {
			isValid: false,
			otpNumber: '',
			remember: false,
			user: {
				password: '',
				passwordConfirmation: '',
			},
			password: {
				show: false,
				confirmed: false,
			},
		}
	},
	methods: {
		handleSendOtp(number) {
			this.$emit('handleSendOtp', number)
		},
		resendVerificaiton() {
			if (!this.$refs.changePassword.validate()) {
				this.$utils.scrollIntoError(this)
				return
			}
			this.$emit('resetPass', this.user)
		},
	},
}
</script>

<style lang="scss">
.v-input__append-inner .v-icon {
	color: #a53e50 !important;
}
.social-button {
	background-color: rgba(255, 255, 255, 0.17) !important;
}
.otpForm {
	width: calc(64px * 4) !important;
	.v-input {
		flex: 1 0 64px !important;
	}
	.v-input__slot {
		padding-bottom: 28px !important;
		background-color: transparent !important;
		input {
			font-size: 32px !important;
			background: #722432 !important;
		}
	}
}
</style>
